 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="110px">
			<el-form-item class="el_form_item" label="货物名称">
				<el-input class="el_input" v-model="form.cargo_name" placeholder="货物名称搜索" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="发货地区">
				<el-cascader class="el_input" v-model="form.case_city" placeholder="请选择车型" :options="cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="卸货地区">
				<el-cascader class="el_input" v-model="form.aim_city" placeholder="请选择车型" :options="cities" filterable></el-cascader>
			</el-form-item>
			<el-form-item class="el_form_item" label="结算方式">
				<el-select class="el_input" v-model="form.settlement_type" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="按吨数" value="1"></el-option>
					<el-option label="按方数" value="2"></el-option>
					<el-option label="按趟" value="3"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="必填货物重量">
				<el-select class="el_input" v-model="form.is_need_weight" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="必填货物体积">
				<el-select class="el_input" v-model="form.is_need_volume" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item" label="是否双边">
				<el-select class="el_input" v-model="form.is_need_return" clearable>
					<el-option label="不限" value=""></el-option>
					<el-option label="是" value="1"></el-option>
					<el-option label="否" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<el-button type="success" @click="to_add_view">添加</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading">
				<el-table-column label="添加时间" width="190">
					<template slot-scope="scope">
						<div>编号:{{scope.row.bl_num}}</div>
						<div>创建:{{scope.row.creat_time_text}}</div>
						<div>名称:{{scope.row.bl_name?scope.row.bl_name:'未设置'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.case_prov}}/{{scope.row.case_city}}/{{scope.row.case_county}}</div>
						<div class="tab_line_item">{{scope.row.case_other_obj.addr_info}}</div>
						<div class="tab_line_item">联系人:{{scope.row.case_link_man_text}} / {{scope.row.case_link_tel_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.aim_prov}}/{{scope.row.aim_city}}/{{scope.row.aim_county}}</div>
						<div class="tab_line_item">{{scope.row.aim_other_obj.addr_info}}</div>
						<div class="tab_line_item">联系人:{{scope.row.aim_link_man_text}} / {{scope.row.aim_link_tel_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货物名称/运输距离"  width="130">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.cargo_name}}/{{scope.row.cargo_type}}</div>
						<div class="tab_line_item">距离:{{scope.row.distance}}KM</div>
					</template>
				</el-table-column>
				<el-table-column label="运费信息"  width="120">
					<template slot-scope="scope">
						<div class="tab_line_item">结算:{{scope.row.settlement_type_text}}</div>
						<div class="tab_line_item">运费:{{scope.row.freight_total_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="必填项/回程"  width="165">
					<template slot-scope="scope">
						<div style="display:flex;justify-content: space-between;">
							<div>
								<div class="tab_line_item">必填重量:{{scope.row.is_need_weight_text}}</div>
								<div class="tab_line_item">必填体积:{{scope.row.is_need_volume_text}}</div>
							</div>
							<div>
								<div class="tab_line_item">自动发车:{{scope.row.is_auto_tord_start_text}}</div>
								<div class="tab_line_item">是否双边:{{scope.row.is_need_return_text}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change"
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 20, 30, 40]"
			:page-size="page.num" 
			:total="list.total*1"
			layout="total, sizes,prev, pager, next, jumper" 
		>
		</el-pagination>



	</div>
</template>

<script>
	import {regionDataPlus,CodeToText} from 'element-china-area-data'
	export default {
		data() {
			return {

				//省市县基础数据
				cities:regionDataPlus,

				//搜索条件
				form: {
					user_tel:'',//客户联系电话
					user_name:'',//客户联系人
					company_name:'',//客户公司名称
					cargo_name:'',//货物名称
					case_city:null,//发货省市区
					aim_city:null,//卸货省市区
					is_need_weight:'',//是否必填货物重量(1:是,2:否)
					is_need_volume:'',//是否必填货物体积(1:是,2:否)
					is_need_return:'',//是否回程(1:需要,2:不需要)
				},

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				//货源二维码
				qrcode:{
					is_show:0,
					src:'',
					bl_data:{}
				},
				
				//绑定的司机列表
				driver_list:{
					is_show:0,
					bl_info:{},
				},
			}
		},
		created() {

			//读取页面数据
			this.get_page_data()
		},
		methods: {

			//打开指定的司机列表
			open_driver_list(item){
				this.driver_list.bl_info={...item}
				this.driver_list.is_show++
			},

			//生成线路二维码
			get_bl_qrcode(item,code_type){

				//调接口
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'get_bl_qrcode',
						bl_num:item.bl_num,
						code_type
					},
					callback:(data)=>{

						//置入src
						this.qrcode.src=data.img

                        this.qrcode.bl_data={...item}

						//打开
						this.qrcode.is_show++
					}
				});
			},

			//删除
			bl_del(item){

				//询问
				this.$my.other.confirm({
					content:"点击'确定'删除路线",
					confirm:()=>{

						//调用接口
						this.$my.net.req({
							data:{
								mod:'truck_tord_real_time',
								ctr:'tord_real_time_bl_del_sub_user',
								bl_num:item.bl_num
							},
							callback:(data)=>{
								
								//读取页面数据
								this.get_page_data()
							}
						})
					}
				});
			},
			
			//前往添加界面
			to_add_view(){

				//跳转
				this.$router.push({
					path:'/pages/sub_user/main_user_sys/business_line/bl_creat'
				});
			},

			//前往修改界面
			to_edit_view(item){

				//跳转
				this.$router.push({
					path:'/pages/sub_user/main_user_sys/business_line/bl_edit?id='+item.id
				});
			},

			//清空查询条件
			ser_para_clear(){
				this.form={
					user_tel:'',//客户联系电话
					user_name:'',//客户联系人
					company_name:'',//客户公司名称
					cargo_name:'',//货物名称
					case_city:null,//发货省市区
					aim_city:null,//卸货省市区
					is_need_weight:'',//是否必填货物重量(1:是,2:否)
					is_need_volume:'',//是否必填货物体积(1:是,2:否)
					is_need_return:'',//是否回程(1:需要,2:不需要)
				}
				this.get_page_data()
			},

			//搜索
			page_ser(){

				//读取第一页
				this.page.p=1

				//读取
				this.get_page_data()
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//读取页面数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'truck_tord_real_time',
						ctr:'tord_real_time_bl_list_by_sub_user',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false

						//总数
						this.list.total=data.max

						//预处理
						for(let item of data.list){

							//时间
							item.creat_time_text=this.$my.other.totime(item.creat_time);

							//装货联系人
							if(item.case_link_man){
								item.case_link_man_text=item.case_link_man;
							}else item.case_link_man_text="无";

							//装货联系电话
							if(item.case_link_tel){
								item.case_link_tel_text=item.case_link_tel;
							}else item.case_link_tel_text="无";

							//装货地其他数据
							item.case_other_obj=JSON.parse(item.case_other);

							//卸货联系人
							if(item.aim_link_man){
								item.aim_link_man_text=item.aim_link_man;
							}else item.aim_link_man_text="无";

							//卸货联系电话
							if(item.aim_link_tel){
								item.aim_link_tel_text=item.aim_link_tel;
							}else item.aim_link_tel_text="无";

							//卸货地其他数据
							item.aim_other_obj=JSON.parse(item.aim_other);

							//结算方式(1:按吨数,2:按方数,3:按趟)
							switch(item.settlement_type){
								case '1':item.settlement_type_text="按吨数";break;
								case '2':item.settlement_type_text="按方数";break;
								case '3':item.settlement_type_text="按趟";break;
							}

							//运费
							if(item.freight_total>0){
								item.freight_total_text=item.freight_total+" 元";
							}else item.freight_total_text="未设置"

							//是否必填货物重量(1:是,2:否)
							switch(item.is_need_weight){
								case '1':item.is_need_weight_text="是";break;
								case '2':item.is_need_weight_text="否";break;
							}

							//是否必填货物体积(1:是,2:否)
							switch(item.is_need_volume){
								case '1':item.is_need_volume_text="是";break;
								case '2':item.is_need_volume_text="否";break;
							}

							//接单自动发车(1:自动发车,2:不自动发车)
							switch(item.is_auto_tord_start){
								case '1':item.is_auto_tord_start_text="是";break;
								case '2':item.is_auto_tord_start_text="否";break;
							}

							//是否回程(1:需要,2:不需要)
							switch(item.is_need_return){
								case '1':item.is_need_return_text="是";break;
								case '2':item.is_need_return_text="否";break;
							}
						}
						
						//渲染
						this.list.data=data.list
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 140px);
	}
	.el_form_item{
		width: 20%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>